﻿/*  custom styles */

@base-font-family: "Open Sans", Helvetica, Arial, sans-serif;
@heading-font-family: 'Droid Sans', Helvetica, Arial, sans-serif;
/* @heading-font-family: Amiri, Cambria, Times, "Times New Roman", serif; */

@heading-color: #00457C;
@primary-text-color: #111;

@h1-font-size: 32px;
@body-font-size: 16px;
@sidebar-text-size: 0.9em;
@footer-text-size: 0.9em;
@copyright-text-size: 0.9em;

@body-link-color: #3E6798;
@body-link-hover-color: #AD0005;

@footer-link-color: #E5E5E5;
@footer-link-hover-color: #fff;

@divider-color: #D7D5D5;

@menu-color: #fff;
@menu-bg-color: #00457C;
@menu-border-color: #0A5590;

@header-bg-color: #FFF;

@footer-text-color: #fff;
@footer-heading-color: #D6DEAD;
@footer-background-color: #00457C;


.background-opacity(@color, @opacity: 0.5) {
background: @color;
@result: fade(@color, @opacity);
background-color: @result;
}
.border-radius (@radius: 5px) {
-webkit-border-radius: @radius;
-moz-border-radius: @radius;
border-radius: @radius;
}
.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @alpha: 0.5) {
-webkit-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
-moz-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
}
.text-shadow {
/* color | offset-x | offset-y | blur-radius */
text-shadow: #111 1px 1px 1px;
}
.gradient (@origin: left, @start: #ffffff, @stop: #000000) {
background-color: @start;
background-image: -webkit-linear-gradient(@origin, @start, @stop);
background-image: -moz-linear-gradient(@origin, @start, @stop);
background-image: -o-linear-gradient(@origin, @start, @stop);
background-image: -ms-linear-gradient(@origin, @start, @stop);
background-image: linear-gradient(@origin, @start, @stop);
}
.transition (@prop: all, @time: 1s, @ease: linear) {
-webkit-transition: @prop @time @ease;
-moz-transition: @prop @time @ease;
-o-transition: @prop @time @ease;
-ms-transition: @prop @time @ease;
transition: @prop @time @ease;
}
.scale (@factor) {
-webkit-transform: scale(@factor);
-moz-transform: 	 scale(@factor);
-ms-transform: 		 scale(@factor);
-o-transform: 		 scale(@factor);
}
.rotate (@deg) {
-webkit-transform: rotate(@deg);
-moz-transform: 	 rotate(@deg);
-ms-transform: 		 rotate(@deg);
-o-transform: 		 rotate(@deg);
}

@import 'main';
@import 'uikit-overrides';