﻿ 
/*=== main css ===*/
body {
  font-size: @body-font-size;
  font-family: @base-font-family;
}

.clearfix:after {
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}

 /*
box-shadow: none|h-shadow v-shadow blur spread color |inset|initial|inherit;
*/
.box-shadow {
	/*Chrome, Safari*/
    -webkit-box-shadow: 2px 2px 10px 1px rgba(193,193,193,0.5);
	/*Firefox*/
    -moz-box-shadow: 2px 2px 10px 1px rgba(193,193,193,0.5);
	/*Standard Syntax*/
    box-shadow: 2px 2px 10px 1px rgba(193,193,193,0.5);
}

.align_left {
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
}

.align_right {
  float: right;
  margin-left: 20px;
  margin-bottom: 10px;
}

.align_center {
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-border {
  padding: 6px;
  border: 1px #ddd solid;
  background-color: #fff;
}

video {
    height: auto !important;
    width: 100% !important;
}
.video-container {
    margin-top: 3em;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}
.video-wrapper {
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px;
    position: relative;
}
.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.text-center {
  text-align: center;
}

hr {
  width: 90%;
  border: 1px solid @divider-color;
  margin: 20px auto;
}

a, a:link, a:visited {
  text-decoration: none;
}
a {
  outline: none;
}

ul {
  padding-left: 25px;
  list-style-type: square;
}
.main-content li, .main-content-home li, .no-margin li {
  padding-bottom: 7px;
}


/*=== typography ===*/

p {
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  font-family: @heading-font-family;
  color: @heading-color;
  font-weight: normal;
  text-align: left;
  line-height: 1.2em;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
}

h1 {
  font-size: @h1-font-size;
}

h2 {
  font-size: @h1-font-size*0.85;
}

h3 {
  font-size: @h1-font-size*0.75;
}

h4 {
  font-size: @h1-font-size*0.65;
}

h5 {
  font-size: @h1-font-size*0.55;
}

/*=== layout ===*/

.top-margin {
    margin-top: 30px;
}
.bottom-margin {
    margin-bottom: 30px;
}
.top-bottom-margin {
    margin-top: 50px;
    margin-bottom: 30px;
}
.left-margin {
    margin-left: 30px;
}

div.callout-text {
  font-family: Times, "Times New Roman", serif;
  font-style: italic;
  font-size: 25px;
  color: #444;
  text-align: left;
  line-height: normal; 
  margin-top: 20px;
  margin-bottom: 30px;
}

div.header-outer {
  background-color: @header-bg-color;
  padding-bottom: 0px;
}
div.tagline {
  margin-top: 50px;
  font-family: Times, "Times New Roman", serif;
  font-style: italic;
  font-size: 30px;
  line-height: normal;
}
div.address {
  margin-top: 15px;
  font-size: 1em;
  text-align: right;
  line-height: normal;
}
div.top-menu {
  margin-top: 15px;
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  line-height: normal;
}

div.sidebar-text {
  margin-top: 45px;
  margin-bottom: 25px;
  font-size: @sidebar-text-size;
}
div.sidebar-callout {
  margin-top: 55px;
  margin-left: 10%;
  margin-right: 10%;
  font-family: Times, "Times New Roman", serif;
  font-style: italic;
  font-size: 28px;
  text-align: center;
  line-height: normal;  
}
.sidebar-button {
  text-align: center;
  margin-bottom: 30px;
}

div.main-content-home {
  margin-bottom: 45px;
}
div.main-content {
  margin-top: 25px;
  margin-bottom: 25px;
}


div.footer-outer {
  color: @footer-text-color;  
  background: @footer-background-color url(../images/webdesign/footer-bg.jpg) top left repeat-x;
  font-size: @footer-text-size;
}

.footer-outer h3 {
  color: @footer-heading-color;
  font-weight: bold;
}

.footer-outer a, .footer-outer a:link, .footer-outer a:visited {
  color: @footer-link-color;
  text-decoration: none;
}

.footer-outer a:hover {
  color: @footer-link-hover-color;
}
div.footer-wrapper {
  margin-top: 80px;
  padding-bottom: 30px;
}

#footer-links {
    padding-top: 20px;
}

div.copyright {
  font-size: @copyright-text-size;
  text-align: center;  
  margin-top: 20px;
  margin-bottom: 20px;
}

div.profile h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0.25em;
}
div.profile h3 {
    font-size: 16px;
    font-style: italic;
    text-align: center;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}
div.profile p {
    font-size: 14px;
    text-align: center;
    margin-top: 0.25em;
    margin-bottom: 0.45em;
}

/* addons */

/*==== project7 stuff ====*/
.p7STT.right {
  width: 32px;
  height: 50px;
  bottom: 0px;
  top: 90% !important;
  right: 0px;
  margin-bottom: 25px;
  padding: 12px 6px 45px 6px !important;
}
.p7ir2-description {
	background-color: #343434;
	border-bottom: 1px solid #2E2E2E;
  border-top: none;
}
.p7ir2-description.under-image {
  color: #E3E3E3;
  max-width: none;
  max-height: none;
  padding: 10px;
  font-size: 1.1em;
  text-align: left;
  position: relative;
}

/* menu */
div.menu-outer {
  font-size: 1em;
  color: @menu-color;
  background-color: @menu-bg-color;
  border-bottom: @menu-border-color 5px solid;
  padding-top: 10px;
  padding-bottom: 8px;
}
@media only screen and (max-width: 850px) and (min-width: 0px) {
.p7PM3-01.responsive {
  background: #12568F;
}
}

/* media queries */

@media only screen and (min-width: 0px) and (max-width: 1150px) {
  div.tagline {
    margin-top: 30px;
    font-size: 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 960px) {
  div.tagline {
    margin-top: 40px;
    font-size: 24px;
  }
  div.address {
    margin-top: 35px;
    font-size: 1em;
  }
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  div.address {
    margin-top: 25px;
    text-align: center;
  }
}