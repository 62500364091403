
/*=== uikit overrides ===*/

@panel-primary-nav-bg-hover-color: #00457C;
@panel-nav-font-size: 0.9em;

.uk-panel-box {
  background-color: #F7F7F7;
}
.uk-panel-box-primary {
  background-color: #cee6fb;
}
.uk-panel-box-primary .uk-nav li > a {
font-size: 0.9em;
}
.uk-panel-box-primary .uk-nav li > a:hover {
    color: #fff;
    background-color: @panel-primary-nav-bg-hover-color;
}
.mainbox h3.uk-panel-title {
  font-weight: bold;
  text-align: center;
}
.uk-panel-box-primary h3.uk-panel-title {
    color: #111;
    font-size: 1.4em;
}
.uk-button-large.bid {
    min-height: 40px;
    padding: 7px 35px;
    font-size: 24px;
}
h3 span.uk-text-small {
    font-size: 0.65em;
    line-height: 0.5em;
}
.uk-alert {
    color: #111;
    text-shadow: none;
    font-family: Times, "Times New Roman", serif;  
    font-style: italic;
    font-size: 24px;
    line-height: 28px;    
}